<template>
	<b-container >
		<b-row>
			<div class="centered">
				<h1>Ocenenia</h1>
			</div>
			<b-row> <!-- Container of awards -->
				<b-col v-for="(item, index) in svgArr" :key="index" class="award-container pl-4 pr-4 mb-5" cols="12" sm="6" lg="3"> <!-- Award container -->
					<b-row class="h-100 align-content-start justify-content-start">
						<b-col cols="12" class="mb-3 d-flex align-items-center justify-content-center">
							<div class="image-container d-flex align-items-center justify-content-center">
								<img class="align-self-center" :src="item.logo" :alt="item.text" /> <!-- Image of an award -->
							</div>
						</b-col>
						<b-col cols="12" class="text-center">
							<span>{{ item.text }}</span> <!-- Award text -->
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-row>
	</b-container>
</template>

<script>
export default {
	data() {
		return {
			svgArr: [
				{
					logo: require("../_assets/icons/ocenenia/2021_European Citizen Prize_Twitter post_1200x675px_V01.svg"),
					text: "Cena európskeho občana 2021"
				},
				{
					logo: require("../_assets/icons/ocenenia/forbes_30_pod_30_2020_pc2sos.svg"),
					text: "Ocenenie Forbes 30pod30 2020 v kategórii Veda a vzdelávanie"
				},
				{
					logo: require("../_assets/icons/ocenenia/Kópia súboru logo_NCKP_20 (1).svg"),
					text: "Národná cena kariérového poradenstva 2020"
				},
				{
					logo: require("../_assets/icons/ocenenia/lda2019_tpavhy.svg"),
					text: "Ocenenie Lektor roka 2019 v kategórii Young Talent"
				},
				{
					logo: require("../_assets/icons/ocenenia/leaf_w8t3un.svg"),
					text: "Víťaz LEAF Award 2019"
				},
				{
					logo: require("../_assets/icons/ocenenia/logo_generacia3_0_general500px.svg"),
					text: "Postup do EDU akcelerátora Generácia 3.0 2021"
				},
				{
					logo: require("../_assets/icons/ocenenia/nexteria.png"),
					text: "Cena Nexterie za zmenu 2020"
				},
				{
					logo: require("../_assets/icons/ocenenia/social_impact_award_iygcna.svg"),
					text: "Víťaz Social Impact Award Slovakia 2018"
				}
			]
		}
	}
}
</script>

<style lang="scss" scoped>
.award-container {
	border-right: 1px solid var(--a-color-blue-light);

	@media only screen and (max-width: 575px) {
		&:nth-child(n) {
			border-right: none;
		}
	}

	@media only screen and (min-width: 576px) and (max-width: 991px) {
		&:nth-child(2n) {
			border-right: none;
		}
	}

	@media only screen and (min-width: 992px) {
		&:nth-child(4n) {
			border-right: none;
		}
	}

	span {
		font-size: 15px;
	}
}

.image-container {
	width: 200px;
	height: 100px;

	img {
		height: auto;
		width: 100%;
		max-height: 100%;
	}
}

.centered {
	z-index: 1000;
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 4rem;
	h1 {
		margin: 0;
	}
}
</style>
