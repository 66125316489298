<template>
	<b-container class="mt-sm-5">
		<b-row>
			<div class="centered">
				<h1 class="mb-0 mt-sm-5 mb-3">
					<span>{{ $t("Čo sme") }}</span>
					<span class="w-color-orange z-fancy-underline">
						{{ $t("dosiahli!") }}
						<svg-fancy-underline-icon />
					</span>
				</h1>
			</div>
			<b-col
				sm="12"
				lg="4"
				class="box"
				v-for="item in cardArr"
				:key="item.title"
			>
				<div>
					<img :src="item.img" :alt="item.title" />
					<h3>
						<span :style="item.color">{{ item.colorTitle }}</span> <br />
						{{ item.title }}
					</h3>
					<div class="centered">
						<p>
							{{ item.text }}
						</p>
					</div>
				</div>
			</b-col>
		</b-row>
		<b-row class="mt-3">
			<b-col cols="12" class="d-flex align-items-center justify-content-center">
				<b-button
					variant="primary"
					class="a-poppins-btn -wider"
					href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0/"
					target="_blank" rel="noopener noreferrer"
				>{{ $t("Podporte nás") }}</b-button>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	data() {
		return {
			cardArr: [
				{
					img: require("../_assets/Rectangle 336.png"),
					colorTitle: "Naučili sme mladých",
					title: " zodpovedne voliť",
					text:
						"V spolupráci so známymi s YouTubermi sme vytvorili video kurz “Už ste dosť veľkí” o fungovaní vlády a parlamentu a potrebe voliť pred parlamentnými voľbami 2020. Kurz videlo viac ako 200 000 mladých ľudí.",
					color: "color:#0165ff"
				},
				{
					img: require("../_assets/Rectangle 337.png"),
					colorTitle: "Chodíme aj priamo k učiteľom",
					title: " do regiónov",
					text:
						"Neostávame len v online svete. Spustili sme program Zmudri do škôl, v rámci ktorého intenzívne spolupracujeme so školami po celom Slovensku, ktoré aj osobne navštevujeme.",
					color: "color:#F2994A"
				},
				{
					img: require("../_assets/Rectangle 335.png"),
					colorTitle: "Podporili sme učiteľov počas",
					title: " najnáročnejšieho obdobia",
					text:
						"Okrem klasických video kurzov pre študentov sme natočili aj špeciálny video kurz pre viac ako 2000 registrovaných učiteľov o tvorbe náučného video obsahu pre študentov. Zároveň sme vytvorili komunitu s viac ako 1000 inovatívnymi učiteľmi.",
					color: "color:#FB2564"
				}
			]
		}
	},
	components: {
		"svg-fancy-underline-icon": () =>
			import("@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline")
	}
}
</script>

<style lang="scss" scoped>
.centered {
	z-index: 1000;
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 2rem;
	align-items: flex-start;
}
.box {
	display: flex;
	flex-direction: column;
	padding: 2.5rem;
	width: 100%;
	h3 {
		min-height: 5rem;
		margin-bottom: 1rem;
	}
	img {
		margin-bottom: 3rem;
		justify-self: center;
		max-width: 100%;
	}
	p,
	a {
		line-height: 28px;
		font-size: 14px;
		font-weight: 100;
	}
}
@media only screen and (max-width: 991px) {
	.box {
		width: 100%;
		text-align: center;
		h3 {
			height: auto;
		}
		p {
			width: 80%;
			height: auto;
		}
	}
}
</style>
