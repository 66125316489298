<template>
	<div>
		<div class="shadow">
			<img :src="shadow" alt="">
		</div>
		<div class="a-hero-container mb-5">
			<b-container class="d-flex">
				<div class="w-100">
					<h1 class="mb-0 mt-sm-5 mb-3">
						<span>{{ $t("Pridajte sa k nám") }}</span> <br />
						<span>{{ $t("v misii pripraviť mladých") }}</span> <br />
						<span class="w-color-primary z-fancy-underline">
							{{ $t("na život!") }}
							<svg-fancy-underline-icon />
						</span>
					</h1>
					<b-button
						variant="primary"
						class="a-poppins-btn -wider mt-3"
						href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0/"
						target="_blank" rel="noopener noreferrer"
					>{{ $t("Podporte nás") }}</b-button>
				</div>
			</b-container>
		</div>
		<nas-pribeh-about />
		<co-sme-dosiahli-about />
		<ocenenia-about />
		<ako-pomoct-about />
		<napisali-o-nas-about />
	</div>
</template>

<script>
import AkoPomoctAbout from "./components/AkoPomoct-about.vue"
import CoSmeDosiahliAbout from "./components/CoSmeDosiahli-about.vue"
import NapisaliONasAbout from "./components/NapisaliONas-about.vue"
import NasPribehAbout from "./components/NasPribeh-about.vue"
import OceneniaAbout from "./components/Ocenenia-about.vue"

export default {
	components: {
		OceneniaAbout,
		AkoPomoctAbout,
		NapisaliONasAbout,
		CoSmeDosiahliAbout,
		NasPribehAbout,
		"svg-fancy-underline-icon": () =>
			import("@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline")
	},
	data() {
		return {
			shadow: require("./_assets/Polygon 1 (2).svg")
		}
	}
}
</script>

<style lang="scss" scoped>
.shadow{
	position: absolute;
	left: 0;
	top: 5%;
	box-shadow: none !important;
	z-index: -1;
}
.a-hero-container {
	background-image: url("./_assets/headimg.png");
}
li {
	list-style: none;
}

@media only screen and (max-width: 991px) {
	.a-hero-container {
		background-size: cover;
	}
}
</style>
