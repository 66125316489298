<template>
	<b-container>
		<b-row>
			<div class="centered">
				<h1 class="mb-5">
					<span>{{ $t("Ako nám ") }}</span>
					<span class="w-color-primary">{{ $t("môžete") }}</span>
					<span class="w-color-primary z-fancy-underline">
						{{ $t("pomôcť") }}
						<svg-fancy-underline-icon />
					</span>
				</h1>
			</div>
			<b-col sm="12">
				<polozka-ako-pomoct-about
					headlineText="Podporte nás" headlineColor="color:#0165ff"
					:imageUrl="require('../_assets/icons/podporte.svg')" imageAltText="Podporte nás"
				>
					Financie použijeme na rozšírenie našich video kurzov k ešte väčšiemu množstvu mladých ľudí v regiónoch.
					Podporte nás
					<a class="link" href="https://zmudri.teachable.com/p/2-percenta" target="_blank" rel="noopener noreferrer">
						poukázaním 2% dane
					</a>,
					<a class="link" href="https://zmudri.darujme.sk/podpora-zmudri-sk-17ea0/" target="_blank" rel="noopener noreferrer">
						príspevkom cez Darujme.sk
					</a>, alebo prevodom na účet <strong>SK40 8330 0000 0029 0155 6881</strong>.
					Poteší nás ľubovoľný príspevok, ktorý nám pošlete.
				</polozka-ako-pomoct-about>

				<polozka-ako-pomoct-about
					headlineText="Povedzte o nás učiteľom vo vašom okolí" headlineColor="color:#F2994A"
					:imageUrl="require('../_assets/icons/povedzte.svg')" imageAltText="Povedzte o nás učiteľom vo vašom okolí"
				>
					Naše bezplatné video kurzy pomohli už stovkám učiteľov po celom Slovensku. Ak máte nejakých vo svojom okolí, dajte im o nás vedieť.
				</polozka-ako-pomoct-about>

				<polozka-ako-pomoct-about
					headlineText="Pridajte sa k nám" headlineColor="color:#FB2564"
					:imageUrl="require('../_assets/icons/pridajtesa.svg')" imageAltText="Pridajte sa k nám"
				>
					Ste zapálený pre zábavné vzdelávanie? Vždy hľadáme ľudí, s ktorými môžeme spáchať niečo prospešné. Napíšte nám na náš e-mail:
					<a class="link" href="mailto:team@zmudri.sk">
						team@zmudri.sk
					</a>
				</polozka-ako-pomoct-about>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	components: {
		"svg-fancy-underline-icon": () =>
			import("@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline"),
		"polozka-ako-pomoct-about": () =>
			import("@/plugins/appzmudri/about/components/PolozkaAkoPomoct-about.vue")
	},
}
</script>

<style lang="scss" scoped>
.centered {
	z-index: 1000;
	display: flex;
	width: 100%;
	justify-content: center;
}
.wrap {
	display: flex;
	width: 70%;
	justify-content: center;
	margin: 0 auto;
}
.box {
	display: flex;
	height: 15rem;
	flex-direction: row;
	gap: 5rem;
	align-items: center;
	img {
		width: 20%;
		place-self: center;
	}
	.boxContent {
		display: flex;
		width: 70%;
		flex-direction: column;
		gap: 1rem;
	}
	p {
		font-size: 14px;
		width: 80%;
		line-height: 28px;
		font-weight: 100;
	}
}
@media only screen and (min-width: 601px) and (max-width: 991px) {
	.wrap {
		min-height: 18rem;
		width: 100%;
	}
}
@media only screen and (max-width: 600px) {
	.wrap {
		width: 100%;
		text-align: center;
		p{
			margin: 0 auto;
		}
	}
	.centered {
		img {
			max-width: 100%;
		}
	}
	.box {
		flex-direction: column;
		gap: 2rem;
		height: auto;
		img {
			position: relative;
			bottom: -3rem;
		}
	}
}

strong {
	font-weight: 700;
}

a.link {
	font-size: inherit;
	color: #0165ff;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}
</style>
