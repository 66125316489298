<template>
	<b-container class="mt-sm-5 mt-3">
		<b-row>
			<b-col sm="12">
				<div class="box">
					<div>
						<h2>
							<span>{{ $t("Náš") }}</span>
							<span class="w-color-secondary z-fancy-underline fix">
								príbeh!<svg-fancy-underline-icon />
								<img
									src="../_assets/underline/top-courses.png"
									class="a-small-logo "
									id="srd"
									alt=""
								/>
							</span>
						</h2>
						<p>
							Mladí ľudia trávia na internete veľké množstvo času. Fotky,
							instagram, memečka, videá… Každý jeden príspevok postupne formuje
							ich vkus, názory a pohľad na budúcnosť. Povedali sme si, že
							nebudeme len “hundrať” a preto sme sa rozhodli prispieť k lepšiemu
							vzdelávaniu na Slovensku.
						</p>
						<p>
							Ako partia 6 priateľov sme sa spojili a založili neziskový
							projekt, ktorý pomáha mladým pripraviť sa lepšie na reálny dospelý
							život. Chceme preto vytvárať plnohodnotný obsah, aby čas, ktorý
							strávia na internete, bol čo najviac zmysluplný. Každý jeden deň
						</p>
					</div>
					<div class="picture">
						<img src="../_assets/group.png" />
					</div>
				</div>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
export default {
	data() {
		return {
			text2: require("../_assets/icons/text2.svg")
		}
	},
	components: {
		"svg-fancy-underline-icon": () =>
			import("@/plugins/appzmudri/_theme/icon/fancy-underline.svg?inline")
	}
}
</script>

<style lang="scss" scoped>
p,
a {
	margin: 1rem;
	line-height: 28px;
	font-size: 15px;
	font-weight: 100;
}
h2{
	margin-left: 1rem;
}
.box {
	p {
		min-width: 30vw;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
}
.centered {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	margin: 1rem;
}
.picture {
	max-width: 100%;
	img {
		min-width: 100%;
		max-width: 50vw;
	}
}
@media only screen and (min-width: 1300px) {
	.picture {
		max-width: 70%;
	}
}
@media only screen and (max-width: 991px) {
	.centered {
		justify-content: center;
		img {
			justify-items: center;
			margin-left: 7rem;
		}
	}
	h2{
	margin-left: 0rem;
}
	.box {
		flex-direction: column;
		p {
			justify-items: center;
			width: 100%;
			margin: auto;
		}
		.picture {
			margin-top: 2rem;
			width: 100%;
			img {
				width: 100%;
			}
		}
	}
}
</style>
